import React, { Component } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { getStorage } from "firebase/storage"

// Initialize Firebase
const firebaseConfig = {
    apiKey: "AIzaSyAXjjNs7bIFbpIN6Hu-8aEufzAf6dZHyZo",
    authDomain: "jeffreyseriodotcom.firebaseapp.com",
    projectId: "jeffreyseriodotcom",
    storageBucket: "jeffreyseriodotcom.appspot.com",
    messagingSenderId: "133247151251",
    appId: "1:133247151251:web:e7317c7de70f743a91f166",
    measurementId: "G-JS5VFJYHZS"
};

const firebaseapp = firebase.initializeApp(firebaseConfig)
const firebaseStorage = getStorage(firebaseapp)

export { firebaseapp, firebaseStorage};