import React, { useState, useEffect } from 'react';
import './Contact.css';
//import ReactGA from 'react-ga';


function Contact() {

  useEffect(() => {
    // Track the page view when the Home component mounts
   // ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);


  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [company, setCompany] = useState('');
  const [message, setMessage] = useState('');
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);


  const handleSubmit = (e) => {
    e.preventDefault();
    // Logic to handle form submission goes here
    // You can send the form data to an API or perform any desired action
    //console.log('Form submitted:', { email, name, company, message });
    // Reset form fields


    const url = 'http://www.jeffreyserio.com/contact.php'; // Replace with your PHP file URL
   //const { to, subject, message } = this.props; // Get the email details from props

    // Create the URL query string
    const params = new URLSearchParams({
      email,
      name,
      company,
      message
    });

    // Make the GET request using fetch
    fetch(`${url}?${params}`)
      .then(response => {
        if (response.ok) {
            setIsFormSubmitted(true);
        } else {
          
        }
      })
      .catch(error => {
        
      });

      setEmail('');
      setName('');
      setCompany('');
      setMessage('');
  }
    
  return (

    <div className="contact-container">
        <div className="connect-container">
            <div className="connect-title">Let's Talk</div>
            <div className="email-text">contact@jeffreyserio.com</div>
        </div>

        <div className="contact-form-container">

            {isFormSubmitted ? (
                <div className="email-text" style={{ height:'300px'}} >Thanks for reaching out! Looking forward to talking with you. I'll get back to you as soon as I can.</div>
            ) : (


            <form onSubmit={handleSubmit}>
                <div className="input-container">
                    <label className="input-title">Email*</label>
                    <input className="input-box" 
                    type="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required/>
                </div>
                <div className="input-container">
                    <label className="input-title">Name*</label>
                    <input className="input-box" 
                    type="text"
                    id="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required/>
                </div>
                <div className="input-container">
                    <label className="input-title">Company*</label>
                    <input className="input-box" 
                    type="text"
                    id="company"
                    value={company}
                    onChange={(e) => setCompany(e.target.value)}
                    required/>
                </div>
                <div className="input-container">
                    <label className="input-title">Message*</label>
                    <textarea className="input-box" style={{ height: '150px'}} 
                    id="message"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    required/>
                </div>
                <div className="send-container">
                    <button className="send-button" type="submit">
                    Send
                    </button>
                </div>
            </form>
            )}
        </div>
    

    
     
    </div>
  );
}

export default Contact;



/*

<div className="form-container">
        <form onSubmit={handleSubmit}>
          <div>
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div>
            <label htmlFor="name">Your Name:</label>
            <input
              type="text"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
          <div>
            <label htmlFor="company">Your Company:</label>
            <input
              type="text"
              id="company"
              value={company}
              onChange={(e) => setCompany(e.target.value)}
              required
            />
          </div>
          <div>
            <label htmlFor="message">Message:</label>
            <textarea
              id="message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
            />
          </div>
          <button type="submit">Send</button>
        </form>
      </div>
      */