import React, { Component, useState, useEffect } from 'react';

import Hero from './components/Hero'
import Carousel from './components/Carousel';
import HeroProjectManager from './components/HeroProjectManager';
import HeroProjectImage from './components/HeroProjectImage';
import HeroProjectText from './components/HeroProjectText';
import Footer from './components/Footer';
import Portfolio from './components/Portfolio';
import Contact from './components/Contact';
import Project from './components/Project';
import Futures from './components/Futures';
import ProtectedProject from './components/ProtectedProject';
import About from './components/About';
import CV from './components/CV';
import ThumbnailCarousel from './components/ThumbnailCarousel';
import { base_url } from './components/Constants.js';

import { BrowserRouter as Router, Routes, Route, Link, Navigate, useLocation } from 'react-router-dom';

import logo from './logo.svg';
import './App.css';
import './components/Hero.css';



function App() {


  return (

    <div className="App">
     
       <ScrollToTop />
       <Hero/>
       
        <Routes>
          <Route path="/home" element={<Home />} exact />
          <Route path="/cv" element={<CV />} />
   


          <Route path="/work" element={<Work />} />
          <Route path="/futures" element={<Futures />} />


          <Route path="/protectedproject" element={<ProtectedProject />} />  
          <Route path="/project" element={<Project />} />      
          <Route path="/about" element={<Jeff />} />    
          <Route path="/contact" element={<Contact />} />                          
          <Route path="/" element={<Navigate replace to="/home" />} />
          
        </Routes>
       

        <Footer/> 
        
       
    </div>

    
  );
}

const ScrollToTop = () => {
  const { pathname } = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const thumbs1 = [
  {
    src: base_url + '/images/project-thumbs/audi-paris.png',
    title: 'Audi Paris',
  },
  {
    src: base_url + '/images/project-thumbs/postit.png',
    title: 'PostIt',
  },
  {
    src: base_url + '/images/project-thumbs/anholt.png',
    title: 'Anholt',
  },
  {
    src: base_url + '/images/project-thumbs/audi-sphere.png',
    title: 'Audi Sphere',
  },
  {
    src: base_url + '/images/project-thumbs/uf.png',
    title: 'Audi Urban Future',
  },
  {
    src: base_url + '/images/project-thumbs/alive.png',
    title: 'Alive',
  }
];

const thumbs2 = [
  {
    src: base_url + '/images/project-thumbs/revolver.png',
    title: 'r/evolve/r',
  },
  {
    src: base_url + '/images/project-thumbs/mag.png',
    title: 'MAG',
  },
  {
    src: base_url + '/images/project-thumbs/nora.png',
    title: 'NoRA',
  },
  {
    src: base_url + '/images/project-thumbs/pulse.png',
    title: 'The Pulse Stairs',
  },
  {
    src: base_url + '/images/project-thumbs/mapweb.png',
    title: 'Map Web',
  },
  {
    src: base_url + '/images/project-thumbs/dia_weave.png',
    title: 'DIA Lights',
  }
];

const thumbs3 = [
  {
    src: base_url + '/images/project-thumbs/smart.png',
    title: 'Smart Use of Energy',
  },
  {
    src: base_url + '/images/project-thumbs/birthgps.png',
    title: 'Birth GPS',
  },
  {
    src: base_url + '/images/project-thumbs/co2030.png',
    title: 'CO2030',
  },
  {
    src: base_url + '/images/project-thumbs/futurebirth.png',
    title: 'The Future Birth Room',
  },
  {
    src: base_url + '/images/project-thumbs/lindholm.png',
    title: 'Lindholm',
  },
  {
    src: base_url + '/images/project-thumbs/saxo.png',
    title: 'Saxo Reactive Ceiling',
  }
];

function Home() {

  const textstyle = {
    color: "black",
    backgroundColor: "white",
    fontSize: "4em",
    fontStyle: "italic",
    marginTop:"60px"
   
  };





  return (
  

    
  <div>

  <div className="text-container">
        <h2 className="large-text">HI, I'M <br></br>JEFFREY SERIO!</h2>
        
      <div className="description-container">
        <p className="small-text">I am a creative, analytical, and award winning digital product designer crafting exceptional user experiences across platforms, products, and services. With an insatiable curiosity to understand people and a strong foundation in design and technology, I offer a distinctive combination of empathy, creativity, and technical expertise. Simplifying complexity and clarifying ambiguity by making innovative solutions tangible to captivate and capture value.</p>
      </div>
  </div>
  <HeroProjectManager/>
 

   <div className="project-container" style={{ backgroundColor:'white', marginTop:'10em'}}>
      <div className='app-seperator-left'>

  
          <Link to='/futures' style={{ textDecoration:'none'}}>  
            <HeroProjectText style={{ backgroundColor:'white'}}
              key={'1'}
              subject={'Publication'}
              title={'Design & Futures'}
              description={'A comprehensive book presenting a set of methods integrating design and foresight. The book offers a step-by-step guide drawing from real-life work. Empowering readers to uncover emerging user needs, technological trends, synthesize insights, envision future worlds, and build products manifesting desired futures.'}
              backgroundcolor={'white'}
              fontstyle = {textstyle}
              
            />
            </Link>
            </div>
            <Link to='/futures' style={{ textDecoration:'none'}}>  
            <HeroProjectImage 
              key={'1'}
              image={base_url + '/images/signals_together.png'}
            /></Link>
        </div>
  
      <div className="project-container" style={{ backgroundColor:'white', marginTop:'10em'}}>
      <div className='app-extra-seperator'>
      {/*  <HeroProjectImage style={{ width:'50%', paddingLeft:'44%' }}
                key={'1'}
                image={'http://localhost:3000/images/jds_presenting.png'}
              /> */}
        </div>
      
          <div className='app-seperator-left'>
            <div href={base_url + "/images/jeffrey-david-serio.jpg"} style={{ textDecoration:'none'}}>
            <a href={"https://link.springer.com/chapter/10.1007/978-3-031-35989-7_16"} style={{ textDecoration:'none'}}>
              <HeroProjectText style={{ backgroundColor:'white'}}
                key={'1'}
                subject={'Event'}
                title={'HCI Int 2023'}
                description={'I am presenting at the 25th International Conference On Human-Computer Interaction at Bella Center, Copenhagen, DenmarK 23-28 July 2023 on Design, Futures, and User Exeperience. Come and see me talk or read the paper!'}
                backgroundcolor={'white'}
                fontstyle = {textstyle}
                
              />
              </a>
            </div>
          </div>
            
        </div>
         <div>

         
  
         <div className="project-container" style={{ backgroundColor:'white', marginTop:'10em', marginLeft:'0%'}}>
        
        
        <div style={{width:'100%'}}>
        
        <Link to='/work' style={{ textDecoration:'none'}}>  
          <div className='home-portfolio-container'>
            <h2 className='home-portfolio-title underline-animation'>Portfolio</h2>
          </div>
          <ThumbnailCarousel images={thumbs1} direction="left" />
          <ThumbnailCarousel images={thumbs2} direction="right"/>
          <ThumbnailCarousel images={thumbs3} direction="left"/>
        </Link>

        </div>


        </div>

       
      
     
     
    </div>
  
  
  
  </div>
  );
}

function Work()
{
  return <Portfolio/>
}

function Jeff()
{
  return <About/>
}
/*
function Contact()
{
  return <p>Contact</p>;
}




<a href={base_url + "/images/jeffrey-david-serio.jpg"} style={{ textDecoration:'none'}}>
*/




export default App;
