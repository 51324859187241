import React from 'react';
import './Footer.css';
import { base_url } from './Constants.js';

const Footer = () => {
  return (
    <div className="footer-container">
      <div className="column">Contact <div className="footer-text">+4593846127</div><div className="footer-text">contact@jeffreyserio.com</div></div>
      <div className="column">Place<div className="footer-text">Aarhus</div><div className="footer-text">Denmark</div></div>
      <div className="column">Follow<div>
         <a href="https://www.instagram.com/jeffreyserio/"><img src={base_url + '/images/insta.png'} alt="Profile" className="social-img" /></a>
         <a href="https://www.linkedin.com/in/jeffreyserio/"><img src={base_url + '/images/linkedin.png'} alt="Profile" className="social-img" /></a>
        </div>
      </div>
    </div>
  );
};

export default Footer;