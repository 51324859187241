import React, { useState, useEffect } from 'react';
import './Carousel.css' //will be added later

const Carousel = (props) => {

    const {children} = props
   

    const [currentIndex, setCurrentIndex] = useState(0)
    const [length, setLength] = useState(children.length)

    //const [currentBannerIndex, setCurrentBannerIndex] = useState(0);

    /*
    useEffect(() => {
        setLength(children.length)
    }, [children])
    */

    useEffect(() => {
        setLength(children.length)
        const currentIndex = setInterval(() => {

            

            setCurrentIndex((prevState) =>
          prevState === children.length - 1 ? 0 : prevState + 1);
        /*
            (prevState + 1) % children.length);
          const firstElement = children.shift();
          children.push(firstElement);
          */

        }, 10000);

        
    
        return () => {
          clearInterval(currentIndex);
        };
      }, [children.length]);

    const next = () => {
        if (currentIndex < (length - 1)) {
            setCurrentIndex(prevState => prevState + 1)
        }
    }
    
    const prev = () => {
        if (currentIndex > 0) {
            setCurrentIndex(prevState => prevState - 1)
        }
    }

    

   

    return (
        <div className="carousel-content-wrapper">
        <div className="carousel-content" style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
                        {children}
        </div>
        </div>
     /*}
        <div className="carousel-container">
            <div className="carousel-wrapper">
            
                <div className="carousel-content-wrapper">
                <div className="carousel-content" style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
                        {children}
                    </div>
                </div>
               
            </div>
        </div>
    */
    )
}

export default Carousel