import React from 'react';
import './SkillsList.css'; // CSS file for styling

const SkillsList = ({ skills }) => {
  return (
    <div className="skills-list-container">
      {skills.map((skill, index) => (
        <div className="skill-box" key={index}>
          {skill}
        </div>
      ))}
    </div>
  );
};

export default SkillsList;