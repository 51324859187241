import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter as Router, Routes, Route, Link, Navigate, useLocation } from 'react-router-dom';

import ReactGA from 'react-ga4';
// Initialize react-ga with your GA4 Measurement ID
ReactGA.initialize('G-0YTHTSLFQK');



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    
    <Router> 
    <App />
    </Router>
   
  </React.StrictMode>
);


