import React, { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import Components from './components';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';

const storage = getStorage();

const ProtectedProject = (props) => {
  const location = useLocation();
  const state = location.state;
  const [imageURLs, setImageURLs] = useState([]);

  useEffect(() => {
    if (state) {
      Promise.all(state.map(getImageURL))
        .then((urls) => {
          setImageURLs(urls);
        })
        .catch((error) => {
          //console.log('Error retrieving image URLs: ', error);
        });
    }
  }, [state]);

  const getImageURL = (item) => {
    return new Promise((resolve, reject) => {
      if (item && item.heroimageurl) {
        const storageRef = ref(storage, item.heroimageurl);

        getDownloadURL(storageRef)
          .then((url) => {
            resolve(url);
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        reject(new Error('Invalid item or missing heroimageurl'));
      }
    });
  };

  return (
    <>
      {state && (
        <div>
          {state.map((item, index) => {
            return (
              <div key={index}>
                <div className="project-description-container">
                  <div className="project-title">{item.projecttitle}</div>
                  <div className="project-client">{item.client}</div>
                </div>
                <div className="feature-fill">
                  {imageURLs[index] && <img src={imageURLs[index]} alt="Example" />}
                </div>
                <div className="project-description-container">
                  <div className="project-description">{item.largetag}</div>
                  <div className="project-content">
                    {item.content.body.map((block) => Components(block))}
                    <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '6em' }}>
                      <Link to="/work">
                        <div className="projects-button">BACK TO WORK</div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default ProtectedProject;
