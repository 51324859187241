import React, { useEffect, useRef } from 'react';
import './ThumbnailCarousel.css'; // CSS file for styling
import { thumb_spacing } from './Constants.js';

const ThumbnailCarousel = ({ images, direction = 'right' }) => {
  const carouselRef = useRef(null);

  useEffect(() => {
    const carouselContainer = carouselRef.current;
    let currentOffset = 0;
    let animationFrameId;
    let isPaused = false;

    const animateCarousel = () => {
      if (!isPaused) {
        if (direction === 'right') {
          currentOffset -= 0.4;
          const firstImage = carouselContainer.children[0];
          const firstImageWidth = firstImage.offsetWidth + thumb_spacing;

          if (currentOffset <= -(firstImageWidth + thumb_spacing)) {
           
            carouselContainer.appendChild(firstImage);
            currentOffset +=  firstImageWidth;
          }
        } else if (direction === 'left') {
          currentOffset+=0.4;
          const lastImage = carouselContainer.children[carouselContainer.children.length - 1];
          const lastImageWidth = lastImage.offsetWidth;

          if (currentOffset >= 0) {
            
            carouselContainer.insertBefore(lastImage, carouselContainer.firstChild);
            currentOffset -=  lastImageWidth + thumb_spacing;
          }
        }

        carouselContainer.style.transform = `translateX(${currentOffset}px)`;
      }

      animationFrameId = requestAnimationFrame(animateCarousel);
    };

    animateCarousel();

    return () => {
      cancelAnimationFrame(animationFrameId);
    };
  }, [images, direction]);

  return (
    <div className="thumbnail-carousel">
      <div className="thumb-overlay">
      <div className="thumbnail-container" ref={carouselRef}>
      
        {images.map((image, index) => (
          
          <div className="thumbnail" key={index}>
            <div className='thumbnail-circle'>
            <img src={image.src} alt={image.title} />
            </div>
            <div className="thumbnail-title">{image.title}</div>
          </div>
        ))}
     
      </div>
      </div>
    </div>
  );
};

export default ThumbnailCarousel;
