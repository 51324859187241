import React, { Component } from 'react';
import { firebaseapp, firebaseStorage } from './FirebaseConfig';
import { ref, list, listAll, getDownloadURL } from "firebase/storage"




class AuthComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: 'contact@jeffreyserio.com',
      password: '',
      user: null, // User state to store the logged-in user
      data: null // State to store the retrieved data
    };
  }

  componentDidMount() {
    // Add an observer for the Firebase authentication state
    this.unsubscribe = firebaseapp.auth().onAuthStateChanged((user) => {
      this.setState({ user });

      if (user) {
        // Retrieve data from Firestore when the user is logged in
        this.fetchData();
      } else {
        // Reset data when the user is logged out
        this.setState({ data: null });
      }
    });
  }

  componentWillUnmount() {
    // Clean up the observer on component unmount
    this.unsubscribe && this.unsubscribe();
  }

  handleLogin = async () => {
    const { email, password } = this.state;

    try {
      await firebaseapp.auth().signInWithEmailAndPassword(email, password);
     // console.log('Login successful!');
      
    } catch (error) {
     // console.error('Login error:', error);
    }
  };

  handleSignup = async () => {
    const { email, password } = this.state;

    try {
      await firebaseapp.auth().createUserWithEmailAndPassword(email, password);
      // console.log('Signup successful!');
    } catch (error) {
      // console.error('Signup error:', error);
    }
  };

  handleLogout = async () => {
    try {
      await firebaseapp.auth().signOut();
      // console.log('Logout successful!');
    } catch (error) {
      // console.error('Logout error:', error);
    }
  };

  fetchData = async () => {
    try {
        const collectionRef = firebaseapp.firestore().collection('ProtectedProjects');
        const snapshot = await collectionRef.get();
        const data = [];
    
        snapshot.forEach((doc) => {
          data.push({ id: doc.id, ...doc.data() });
        });
    
        //console.log('Data retrieved:', data);
        this.setState({ data });


        const jsonData = JSON.stringify(data, null, 2)
    
        // Call the onData callback function with the retrieved data
        this.props.onData && this.props.onData(data);

    } catch (error) {
      // console.error('Error retrieving data:', error);
    }
  };

  render() {
    const { user, data } = this.state;

    return (
      <div>
        {this.props.children}

        {user ? (
          <div>
              <div className="form-outline" >
                    <div style={{marginTop:'1em'}}>
                        <div className="portfolio-heading-text">Yeah, thanks for checking out my portfolio!</div>
                    </div>
                    <button style={{display:'block', marginTop:'1em'}}className="form-button" onClick={this.handleLogout}>
                            Hide
                    </button>
                  
              </div>
            
           
          </div>
        ) : (
          <div>
            
            <div className="form-outline" >
                    <div style={{marginTop:'1em'}}>
                        <div className="portfolio-heading-text">See the whole portfolio of work:</div>
                    </div>

                    <div className="form-container">
                        
                        <input
                           type="password"
                           placeholder="Password"
                           value={this.state.password}
                            onChange={(e) => this.setState({ password: e.target.value })}
                            className="form-input"

                            
                        />

                       
                        <button className="form-button" onClick={this.handleLogin}>
                            Show
                        </button>
                       
                    </div>
                </div>
            {/*
            <input
              type="email"
              placeholder="Email"
              value={this.state.email}
              onChange={(e) => this.setState({ email: e.target.value })}
            />

            <input
              type="password"
              placeholder="Password"
              value={this.state.password}
              onChange={(e) => this.setState({ password: e.target.value })}
            />
            <button onClick={this.handleLogin}>Login</button>
           <button onClick={this.handleSignup}>Signup</button> */}
          </div>
        )}
{/*
        {data && (
          <div>
            <h2>Data from Firestore</h2>
            <pre>{JSON.stringify(data,)}</pre>
          </div>
        )}
        */}
        
      </div>
    );
  }
}

export default AuthComponent;