import React, { Component } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import './Portfolio.css';
import AuthComponent from './AuthComponent';
import { firebaseStorage } from './FirebaseConfig';
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { base_url, portfolioData } from './Constants.js';
//import ReactGA from 'react-ga';


const storage = getStorage();

const getDownloadUrl = (refstorage) => {
    return new Promise((resolve, reject) => {
      getDownloadURL(refstorage)
        .then((url) => {
         // console.log("here is the url " + url);
          resolve(url);
        })
        .catch((error) => {
          // Handle any errors
          reject(error);
        });
    });
  };


//const childRef = storageRef.child('example.jpg');

class Portfolio extends Component {
    constructor(props) {
      super(props);
      this.state = {
        inputValue: '',
        protecteddata:[],
        imageUrls: [] // New state variable for storing image URLs
      };
    }

    componentDidMount() {
      // Track the page view when the Home component mounts
      //ReactGA.pageview(window.location.pathname + window.location.search);
    }

    handleData = (data) => {
        this.setState({ protecteddata: data }, () => {
          const promises = this.state.protecteddata.map((protecteditems) => {
            if (protecteditems.featureimageurl) {
              return getDownloadUrl(ref(firebaseStorage, protecteditems.featureimageurl));
            } else {
             // console.log("path undefined");
              return Promise.resolve(null);
            }
          });
    
          Promise.all(promises)
            .then((urls) => {
             // console.log("setting the URLs:");
              this.setState({ imageUrls: urls });
            })
            .catch((error) => {
             // console.error("Error retrieving download URLs:", error);
            });
        });
      };

    

    

    handleChange = (event) => {
        this.setState({ inputValue: event.target.value });
      };

    handleSubmit = (event) => {
        event.preventDefault();
      
        // Add your submit logic here
    };

   

  

    
     ProjectFeatured = ({ id, featuredtitle, featuredlink, featureimageurl, featureddescription, project}) => {

    
      
       // console.log(featuredtitle + " the project page is made up of " + JSON.stringify(project));
        return (
            <div className="featured-project-container">
            <div className="featured-project">
                    <Link to={featuredlink} state={project} style={{textDecoration:'none'}}>
                       
                                                
                            <div className="featured-text-container show-on-scroll">
                            <div className="featured-text-title">{featuredtitle}</div>
                                <div className="featured-text-description">
                                   {featureddescription}
                                </div>
                            </div>
                            {<img className="featured-image" src={featureimageurl}/>}   
                    </Link>
                </div>
                </div>
        );
      };


   

    render() {

        

        return (



            <div className='portfolio-container'>

                <div className='portfolio-text-container'>
                    <div className="text-column">Strategy</div>
                    <div className="text-column">Research</div>
                    <div className="text-column">Design</div>
                    <div className="text-column">Technology</div>
                </div>

                <AuthComponent onData={this.handleData}>
              
                </AuthComponent>
               

            {this.state.protecteddata.length > 0 ?
                    <div>
                    
                    {this.state.protecteddata.map((protecteditems, index) => {

                        

                        return(
                        <this.ProjectFeatured
                        key={protecteditems.id}
                        featuredtitle={protecteditems.featuredtitle}
                        featuredlink={protecteditems.featuredlink}
                        featureimageurl={this.state.imageUrls[index]} // Access the URL from the state
                        featureddescription={protecteditems.featureddescription}
                        project={[protecteditems.project]}
                        />
                        );
                    })}

                    </div>
            : console.log("Empty")}
                <div>
                    {portfolioData.map((item) => (
                        
                        <this.ProjectFeatured key={item.id} featuredtitle={item.featuredtitle} featuredlink={item.featuredlink}  featureimageurl={item.featureimageurl} featureddescription={item.featureddescription} project={item.project} />
                    ))}
                </div>

                <div>
          
            </div>
        </div>
        );
    }

}

export default Portfolio;


                       