import React from 'react';
import './VideoPlayer.css'

export default props => (
    <div className='video-container'>
    <video controls={"1"} src={props.block.text} type={"video/mp4"} class={"media-document mac video"}></video>
    </div>
  );


// <video controls={"1"} autoplay={"0"} src={props.block.text} type={"video/mp4"} class={"media-document mac video"}></video>