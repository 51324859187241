import React from "react";
import FullParagraph from "./FullParagraph";
import LeftParagraph from "./LeftParagraph";
import ImageContent from "./ImageContent";
import RightBold from "./RightBold";
import RightParagraph from "./RightParagraph";
import Foo from "./Foo";
import VideoPlayer from "./VideoPlayer";
import ProtectedImage from "./ProtectedImage";

const Components = {
  leftparagraph: LeftParagraph,
  fullparagraph: FullParagraph,
  imagecontent:ImageContent,
  rightbold:RightBold,
  rightparagraph:RightParagraph,
  foo: Foo,
  videoplayer:VideoPlayer, 
  protectedimage:ProtectedImage
};

export default block => {
  if (typeof Components[block.component] !== "undefined") {
    return React.createElement(Components[block.component], {
      key: block._uid,
      block: block
    });
  }
  return React.createElement(
    () => <div>The component {block.component} has not been created yet.</div>,
    { key: block._uid }
  );
};
