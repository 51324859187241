export const base_url = "http://www.jeffreyserio.com";
export const thumb_spacing = 60;


export const portfolioData = [
    {
        id:"0",
        featuredtitle: "Birth GPS",
        featuredlink:"/project",
        featureimageurl: base_url + "/images/project-images/birth-gps/birth_gps_01.png",
        featureddescription: "Navigating the Journey of Birth with Confidence and Clarity",
        project:[{
            heroimageurl: base_url + "/images/project-images/birth-gps/birth_gps_05.png",
            client:"Herning Hospital",
            projecttitle: "Birth GPS",
            largetag:"Navigating the Journey of Birth, Calm, Confident and with Clarity",
            partners:"Electrotexture, Esben Bala Skoube",
            shortdescription:"Birth GPS is an extraordinary app designed to empower expectant mothers and fathers on their transformative journey through childbirth. With its precise tracking, intuitive features, and the ability to choose from a range of atmospheres and moods, Birth GPS ensures a personalized and empowering birthing experience in The Future Delivery Room.",
            content:{
                body:[
                    {
                        id:"1",
                        component:"leftparagraph",
                        text:"The Birth GPS empowers expectant mothers and father to exert complete control over the ambiance of their birthing environment and an indispensable tool that utilizes advanced algorithms to provide a visualized estimation of the birth time, allowing parents to better prepare for each stage of labor."
                    },
                    {
                        id:"2",
                        component:"fullparagraph",
                        text:"Mothers can immerse themselves in serene and breathtaking landscapes projected across every corner of the room, transforming the sterile hospital setting into a tranquil haven. The app also features the ability to select different atmospheres, moods, sounds, and soothing colored lights that help create a calming and relaxing atmosphere, promoting a sense of peace and serenity. "
                    },
                    {
                        id:"3",
                        component:"fullparagraph",
                        text:"Recognizing the importance of the expecting father's role, the app also allows fathers to actively participate and contribute to the birth experience. By providing them with a feeling of control and helpfulness, Birth GPS ensures that both parents can share in the joy, support, and emotional connection during this monumental event, fostering a deep bond and a positive birthing experience for the entire family."
                    },
                    {
                        id:"4",
                        component:"imagecontent",
                        text:base_url + "/images/project-images/birth-gps/birth_gps_08.png"
                    },
                    {
                        id:"4",
                        component:"imagecontent",
                        text:base_url + "/images/project-images/birth-gps/birth_gps_02.png"
                    },
                    {
                        id:"5",
                        component:"rightparagraph",
                        text:"The Birth GPS equips parents with knowledge about the progression of labor, providing detailed descriptions and insights, allowing them to navigate each phase with confidence and support. Ultimately, this invaluable functionality helps expectant parents stay informed, mentally and emotionally engaged, and actively involved in the birth process, fostering a deeper sense of partnership and mutual understanding between the mother and father during this transformative journey."
                    },
                    {
                        id:"4",
                        component:"imagecontent",
                        text:base_url + "/images/project-images/birth-gps/birth_gps_09.png"
                    },
                    {
                        id:"6",
                        component:"imagecontent",
                        text:base_url + "/images/project-images/birth-gps/birth_gps_03.png"
                    },
                    {
                        id:"7",
                        component:"fullparagraph",
                        text:"Immersive imagery carefully curated, depicting the serene and familiar Danish landscapes that evoke feelings of protection and calm. These captivating visuals, captured across different seasons and times of day, transport you to meaningful locations, fostering a sense of connection within the hospital setting. Harmonious and soothing colors, carefully selected in tone-based variations, create an enveloping and calming ambiance, embracing the range of emotions—anticipation, excitement, and nervousness—associated with childbirth. The intuitive icons and symbols guide you effortlessly through the Birth GPS, ensuring clear communication and minimizing cognitive load. With their light and airy design, inspired by nature, these icons maintain legibility and accessibility, allowing you to navigate the app with ease. "
                    },
                    {
                        id:"8",
                        component:"imagecontent",
                        text:base_url + "/images/project-images/birth-gps/birth_gps_07.png"
                    },
                    {
                        id:"8",
                        component:"fullparagraph",
                        text:"The components and design system serves as a central repository of design guidelines, patterns, and best practices, ensuring a unified and harmonious user experience across all screens and functionalities. This systematic approach not only saves time and effort but also guarantees a high level of usability, accessibility, and visual coherence. Ultimately, the design components and system create a polished and refined application that seamlessly integrates all the desired features and provides an exceptional user experience for expectant mothers and fathers using Birth GPS."
                    },
                    {
                        id:"9",
                        component:"imagecontent",
                        text:base_url + "/images/project-images/birth-gps/birth_gps_06.png"
                    }
                ]
            }
        }]
    },
    {
    id:"6",
    featuredtitle: "The Virtual Equipment House",
    featuredlink:"/project",
    featureimageurl: base_url + "/images/project-images/driesel/prototypes_3.png",
    featureddescription: "Revolutionizing Smart Construction Equipment Management to Unlock Sustainability, Efficiency, and Profitability",
    project:[{
        heroimageurl: base_url + '/images/project-images/driesel/prototypes_3.png',
        client:"Westerberg & Driesel",
        projecttitle:"The Virtual Equipment House",
        largetag:"Revolutionizing Smart Construction Equipment Management to Unlock Sustainability, Efficiency, and Profitability",
        partners:"",
        shortdescription:"The Virtual Equipment House by Westerberg and Driesel is a groundbreaking digital platform that transforms how smart construction equipment is managed. With a strong emphasis on user experience, this platform drives sustainability, efficiency, and profitability in the construction industry.",
        content:{
            body:[
                {
                    id:"1",
                    component:"fullparagraph",
                    text:"Combining Westerberg's expertise in durable lithium batteries and IoT Smart Metering with Driesel's expansion into sustainable and smart construction equipment, The Virtual Equipment House is a platform of digital twins that opens horizons on managing electrified construction equipment. Making the intangible qualities of electricity usage and sustainability tangible."
                },
                {
                    id:"2",
                    component:"imagecontent",
                    text: base_url + '/images/project-images/driesel/low-fidelity.png'
                },
                {
                    id:"3",
                    component:"leftparagraph",
                    text:"With a vision for a whole new class of electrified equipment in the construction industry, this project places a significant emphasis on data utilization, user needs, and automation to achieve intelligent maintenance systems, real-time monitoring, diagnostics, and predictive analytics, while optimizing performance and utilization through data-driven insights."
                }, 
                {
                    id:"4",
                    component:"imagecontent",
                    text:base_url + '/images/project-images/driesel/3dprint.png'
                },
                {
                    id:"5",
                    component:"fullparagraph",
                    text:"The platform's elements are meticulously designed. Employing a user experience design approach and methodologies the project outlines functional requirements to prioritize construction professionals to effortlessly manage and monitor equipment. The information experience was architected, wireframed, and tested."
                },
                {
                    id:"6",
                    component:"imagecontent",
                    text:base_url + '/images/project-images/driesel/ia.png'
                },
                {
                    id:"6",
                    component:"rightparagraph",
                    text:"By translating Westerberg's battery expertise into the digital realm, this project carefully considers the technical skills and resources required for platform actualization, backcasting was used to define the roadmap and necessary capacities to go from vision and concept to implementation."
                },
                {
                    id:"6",
                    component:"imagecontent",
                    text:base_url + '/images/project-images/driesel/stakeholder.png'
                }
            ]
        }
    }]
},
{
    id:"7",
    featuredtitle: "The Pulse Stairs",
    featuredlink:"/project",
    featureimageurl: base_url + "/images/project-images/pulse/selected_prototype_images_2.png",
    featureddescription: "Transforming Rehabilitation through An Interactive Sensory Experience",
    project:[{
        heroimageurl: base_url + '/images/project-images/pulse/selected_prototype_images_3.png',
        client:"Godstrup Hospital",
        projecttitle:"The Pulse Stairs",
        largetag:" Elevating The Rehabilitation Journey with The Pulse Stairs",
        partners:"",
        shortdescription:"The Pulse Stairs is a groundbreaking rehabilitation installation at the new Godstrup super hospital. It offers a fun, motivating, and exciting experience for children and adults, allowing them to interact with their heart rate and witness the transformative power of climbing stairs by playing with a dynamic light chandelier.",
        content:{
            body:[
                {
                    id:"1",
                    component:"leftparagraph",
                    text:"The Pulse Stairs installation at Godstrup super hospital aims to create a fun and engaging environment where walking up the stairs to the Children and Young People’s Department becomes an exciting experience that resonates with your heart rate, changing your body and the lighting in the stairwells."
                },
                {
                    id:"2",
                    component:"imagecontent",
                    text: base_url + '/images/project-images/pulse/stairs.png'
                },
                {
                    id:"3",
                    component:"fullparagraph",
                    text:"A continuous pulse-sensitive chandelier connects the three floors of the stairwell, providing a visual representation of heart rhythms. Each floor features a podium where children and adults can record their pulse and transmit it to the chandelier or view it on a screen in front of them, providing a truly immersive and physical understanding of their pulse."
                }, 
                {
                    id:"4",
                    component:"fullparagraph",
                    text:"Participants can run up the stairs and observe how the rhythm of light directly correlates to their pulse, creating a unique and engaging experience. This interactive feature not only motivates rehabilitation but also allows healthcare professionals to make the process enjoyable, incorporating play as a motivational tool."
                },
                {
                    id:"5",
                    component:"imagecontent",
                    text:base_url + '/images/project-images/pulse/storyboard.png'
                },
                {
                    id:"6",
                    component:"fullparagraph",
                    text:"The Pulse Stairs combines cutting-edge technology, interactivity, and training motivation, offering an unparalleled interactive experience on a staircase. By transforming a mundane transport space into an intense sensory experience, it motivates daily use, enhances standardized clinical tests, and promotes rehabilitation."
                },
                {
                    id:"7",
                    component:"imagecontent",
                    text:base_url + '/images/project-images/pulse/pulse.gif'
                },
                {
                    id:"8",
                    component:"rightparagraph",
                    text:"The Pulse Stairs installation represents a pioneering approach to rehabilitation, merging technology, interactivity, and motivation. It creates an engaging sensory journey on a staircase, revolutionizing the way individuals perceive and participate in their rehabilitation process, ultimately improving their overall well-being."
                },
                {
                    id:"9",
                    component:"fullparagraph",
                    text:"Cocreative workshops were held with children to help develop games to play on the stairs and test prototypes."
                },
                {
                    id:"10",
                    component:"imagecontent",
                    text:base_url + '/images/project-images/pulse/workshop.JPG'
                }
            ]
        }
    }]
},
{
    id:"1",
    featuredtitle: "The Future Delivery Room",
    featuredlink:"/project",
    featureimageurl: base_url + "/images/project-images/birth/futurebirth-room-featured-1024x683.jpeg",
    featureddescription: "A hybrid  digital and physical sensual experience for our emotional and physical well-being. ",
    project:[{
        heroimageurl: base_url + "/images/project-images/birth/future-birth-room-forest.png",
        client:"Herning Hospital, w/ Electrotexture",
        projecttitle: "The Future Delivery Room",
        largetag:"A hybrid digital and physical sensual experience for our emotional and physical well-being.",
        partners:"Electrotexture, Esben Bala Skoube",
        shortdescription:"Since January 2015 the maternity ward of Herning Hospital has been helping to evoke peace and security to future parents. And calm and well-being to the newest members of the world within their Future Delivery Room.",
        content:{
            body:[
                {
                    id:"1",
                    component:"leftparagraph",
                    text:"For some time now the maternity ward of Herning Hospital has been helping to evoke peace and security to future parents. And calm and well-being to the newest members of the world within their Future Delivery Room."
                },
                {
                    id:"2",
                    component:"fullparagraph",
                    text:"The Future Delivery Room transforms the typical clinical and sterile hospital into an enveloping atmosphere combining the Danish home and nature to ensure a positive birth experience."
                },
                {
                    id:"3",
                    component:"imagecontent",
                    text:base_url + "/images/project-images/birth/future-birth-room-Winter.png"
                },
                {
                    id:"4",
                    component:"rightbold",
                    text:"Personalization, Control, & Transparency Gives Peace of Mind"
                },
                {
                    id:"5",
                    component:"rightparagraph",
                    text:"Essential to the deliver room is ability control and design ones own atmosphere making the room unique to families needs and desires while actively involving the non birthing partner in the birth. The Birth GPS within the app makes the stages of the child birth transparent and informs the future parents where they are in the process and what to expect."
                },
                {
                    id:"6",
                    component:"imagecontent",
                    text:base_url + "/images/project-images/birth/future-birth-room-app.png"
                }
            ]
        }
    }]
},
{
    id:"2",
    featuredtitle: "Linholm",
    featuredlink:"/project",
    featureimageurl: base_url + "/images/project-images/lindholm/lindholm-hoje-featured-1024x528.png",
    featureddescription: "Bringing A Viking Burial Museum Back To Life",
    project:[{
        heroimageurl: base_url + "/images/project-images/lindholm/lindholm-hoje-featured-1024x528.png",
        client:"Linholm Høje, Zenaria",
        projecttitle:"Linholm",
        largetag:"Bringing A Viking Burial Museum Back To Life",
        partners:"Zenaria",
        shortdescription:"Get lost in time to discover the landscape of Northern Jutland, Denmark, during Viking times through a 3D interactive installation. Lindholm brings the museum artefacts to life by entering into a story of the past.",
       content:{
            body:[
                {
                    id:"1",
                    component:"leftparagraph",
                    text:"Get lost in time to discover the landscape of Northern Jutland, Denmark, during Viking times through a 3D interactive installation. Lindholm brings the museum artefacts to life by entering into a story of the past."
                },
                {
                    id:"2",
                    component:"imagecontent",
                    text:base_url + "/images/project-images/lindholm/lindholm-boat-empty.png"
                },
                {
                    id:"3",
                    component:"rightbold",
                    text:"A Rune Stone, a Ship and a Boy"
                },
                {
                    id:"4",
                    component:"rightparagraph",
                    text:"The story starts by entering the secret code found in the museum into the rune stone and climbing on the Viking ship Skuldelev 3. Here can you follow the story of a lost boy who has traveled back in time to the Viking age."
                },
                {
                    id:"5",
                    component:"imagecontent",
                    text:base_url + "/images/project-images/lindholm/lindholm-room.png"
                },
                {
                    id:"6",
                    component:"fullparagraph",
                    text:"The interactive boat allows you to row through the landscape of Northern Jutland learning about significant parts of the region during that period while helping the boy to return home."
                },
                {
                    id:"6",
                    component:"imagecontent",
                    text:base_url + "/images/project-images/lindholm/lindholm-boy-boat.png"
                },
                {
                    id:"7",
                    component:"fullparagraph",
                    text:"The interactive boat allows you to row through the landscape of Northern Jutland learning about significant parts of the region during that period while helping the boy to return home."
                },
                {
                    id:"8",
                    component:"imagecontent",
                    text:base_url + "/images/project-images/lindholm/lindholm-rowing-boat.png"
                },
                {
                    id:"9",
                    component:"rightbold",
                    text:"Exploring Possibilities Through Making"
                },
                {
                    id:"10",
                    component:"imagecontent",
                    text:base_url + "/images/project-images/lindholm/Koncept_rendering01_1024x487px.jpg"
                },
                {
                    id:"11",
                    component:"imagecontent",
                    text:base_url + "/images/project-images/lindholm/lindholm-jeff-prototyping.jpg"
                },
                {
                    id:"12",
                    component:"imagecontent",
                    text:base_url + "/images/project-images/lindholm/lindholm-rune-prototype-1536x1152.jpg"
                }
            ]
        }
    }]
},
{
    id:"3",
    featuredtitle: "Media Architectural Growth",
    featuredlink:"/project",
    featureimageurl: base_url + "/images/project-images/mag/media_architecutral_growth_short.gif",
    featureddescription: "Participations For Imaging The Desirable Future City",
    project:[{
        heroimageurl: base_url + "/images/project-images/mag/mag-1-1024x576.png",
        client:"Media Architecture Biennale",
        projecttitle:"Media Architectural Growth",
        largetag:"Participations For Imaging The Desirable Future City",
        partners:"Electrotexture",
        shortdescription:"Since January 2015 the maternity ward of Herning Hospital has been helping to evoke peace and security to future parents. And calm and well-being to the newest members of the world within their Future Delivery Room.",
        content:{
            body:[
                {
                    id:"1",
                    component:"fullparagraph",
                    text:"What if everyone could inform architecture their city and architectural drawings could grow as processes in nature? "
                },
                {
                    id:"3",
                    component:"leftparagraph",
                    text:"Media Architectural Growth (MAG) was an installation exhibited at the Media Architecture Biennale in Aarhus. MAG speculates on future evolutionary planning strategies and presents potential planning scenarios that explore the relationship between control system, community and entropic environments – a topic highly relevant to people interested in the smart city or participatory technologies."
                }, 
                {
                    id:"4",
                    component:"imagecontent",
                    text:base_url + "/images/project-images/mag/media-architectural-growth-exhibit.png"
                },
                {
                    id:"5",
                    component:"fullparagraph",
                    text:"When computation was introduced to architecture some 60 years ago, its potential to inform new types of architectural practice was understood by Christopher Alexander, who developed a theoretical framework and Archigram who presented a series of new experimental topologies. However, one of the more artistic experiments was the installation, SEEK or Blocksworld. Displayed at the Jewish Museum in New York 1970. The installation was developed by a young Nicolas Negroponte who presented a study of robot building architecture in an entropic environment based on the movements of gerbil colony."
                },
                {
                    id:"6",
                    component:"fullparagraph",
                    text:"Much has happened since, but often the computational processes has been limited to a matter of geometrical control (BIM and parametric design) or regulation of smart lighting. MAG revisits Blockworld to explore radical and speculative design scenarios, that use contemporary media tools and techniques to push discussions of new types of architectures and public spaces."
                },
                {
                    id:"7",
                    component:"fullparagraph",
                    text:"Inviting users to help choose the preferred programs in the city, every design iteration realizes the most popular programs and visitors can follow the evolution of the installation by observing structure of the city captured and displayed on the wall. The installation presents an alternative use of media architecture tools to inform a button up evolutionary planning practice. We ask: What happens if we introduced planning strategies that allow environments to adapt every week instead of updating our city architecture every 30-50 years?"
                },
                {
                    id:"8",
                    component:"imagecontent",
                    text: base_url + "/images/project-images/mag/media-architectural-growth-exhibit-ppl.png"
                },
                {
                    id:"9",
                    component:"rightbold",
                    text:"Evolutionary Design in Action"
                },
                {
                    id:"10",
                    component:"rightparagraph",
                    text:"In the MAG installation the occupancy routines of everyday life in a gerbil colony is captured in a series of OCCUPANCY MAPs. The information is send to an agent based design system, which is inspired by natural agent based organization strategies observed in the evolution of slime-mould aggregation. The occupancy map will function as virtual pheromone tacks attacking the virtual agents, because each agent will leave trails of pheromone, others will follow and slowly new paths will emerge as an emergent bottom-up process. The system will slowly settle into optimal paths  visible in the PHEROMONE MAPs."
                },
                {
                    id:"11",
                    component:"imagecontent",
                    text: base_url + "/images/project-images/mag/media-architecural-growth-gerbil-stopmotion.gif"
                },
                {
                    id:"12",
                    component:"imagecontent",
                    text:base_url + "/images/project-images/mag/media-architectural-growth-table.png"
                },
                {
                    id:"13",
                    component:"imagecontent",
                    text:base_url + "/images/project-images/mag/media-architectural-growth-looking.png"
                }
            ]
        }
    }]
},
{
    id:"4",
    featuredtitle: "Extending The Stages",
    featuredlink:"/project",
    featureimageurl: base_url + "/images/project-images/nibe/nibe_featured-1024x528.png",
    featureddescription: "Illuminating the Intangible Qualities of Music, Nature, and Togetherness",
    project:[{
        heroimageurl: base_url + "/images/project-images/nibe/nibe_featured-1024x528.png",
        client:"Nibe Festival",
        projecttitle:"Extending The Stages",
        largetag:"Illuminating the Intangible Qualities of Music, Nature, and Togetherness",
        partners:"",
        shortdescription:"Since January 2015 the maternity ward of Herning Hospital has been helping to evoke peace and security to future parents. And calm and well-being to the newest members of the world within their Future Delivery Room.",
        content:{
            body:[
                {
                    id:"1",
                    component:"leftparagraph",
                    text:"Extending The Stages explores the potential of embedding technology into the environment to create new relationships between the visitors and the festival’s activities and performances through an interactive lighting design for the Nibe Festival’s Skals Forest providing a relational and contextually meaningful experience."
                },
                {
                    id:"2",
                    component:"imagecontent",
                    text:base_url + "/images/nibefestival_lights.png"
                },
                {
                    id:"3",
                    component:"rightbold",
                    text:"Eliciting the Protectiveness of Nature"
                },
                {
                    id:"4",
                    component:"rightparagraph",
                    text:"Rising up along a hill overlooking the town of Nibe to its south is Skals Forest. It is within this forest that the Nibe Festival takes place. The enormous orbs of light within the heights of the trees of Skals forest blanket the otherwise pitch black canopy and elicit a sense of intimacy and seclusion. This illuminated cover protects and shields the outside transforming it from a forest full of strange and menacing forces to a paradise of joy and escape."
                },
                {
                    id:"5",
                    component:"imagecontent",
                    text:base_url + "/images/project-images/nibe/nibe_trail_night-683x1024.jpg"
                },
                {
                    id:"6",
                    component:"rightbold",
                    text:"Executing on Extending The Stages"
                },
                {
                    id:"7",
                    component:"fullparagraph",
                    text:"Sensors were embedded in each unit to listen and respond to the festival’s music and participants. The units were networked and programmed to coordinate with each other to send waves of light from the stage and to pass messages between festival goers."
                },
                {
                    id:"8",
                    component:"imagecontent",
                    text:base_url + "/images/project-images/nibe/nibe_process.png"
                },
                {
                    id:"9",
                    component:"imagecontent",
                    text:base_url + "/images/project-images/nibe/axonometric_unit.png"
                },
                {
                    id:"10",
                    component:"imagecontent",
                    text: base_url + "/images/project-images/nibe/nibe-festival-dimmers.png"
                }
            ]
        }
    }]
},
{
    id:"5",
    featuredtitle: "Systems Thinking",
    featuredlink:"/project",
    featureimageurl: base_url + "/images/project-images/systemsthinking/system_02.jpg",
    featureddescription: "Workshop on Thinking in Systems For Parametic Design and Production Techniques",
    project:[{
        heroimageurl: base_url + "/images/project-images/systemsthinking/system_04.jpg",
        client:"Aalborg University - Esben Poulsen and Mads Brath",
        projecttitle:"Systems Thinking",
        largetag:"Workshop on Thinking in Systems For Parametic Design and Production Techniques",
        partners:"",
        shortdescription:"Workshop on Thinking in Systems For Parametic Design and Production Techniques",
        content:{
            body:[
                {
                    id:"1",
                    component:"leftparagraph",
                    text:"Learning to leverage the potential of parameteric design and cutting edge production techniques requires to think algorithmically in relations and rules."
                },
                {
                    id:"4",
                    component:"fullparagraph",
                    text:"Invited to organize a weeks long workshop, Systems Thinking, at the Architecture & Design (AOD) department of the University of Aalborg. The workshop, co-facilitated by Mads B. Jensen and Esben S. Poulsen, gathered over a hundred third-semester AOD students. Its main objective was to familiarize the students with computer-oriented design processes, with a specific focus on algorithmic thinking and parametric design."
                },
                {
                    id:"2",
                    component:"imagecontent",
                    text:base_url + "/images/project-images/systemsthinking/system_08.jpg"
                },
                {
                    id:"11",
                    component:"leftparagraph",
                    text:""
                },
                {
                    id:"2",
                    component:"imagecontent",
                    text:base_url + "/images/project-images/systemsthinking/system_03.jpg"
                },
                {
                    id:"3",
                    component:"rightbold",
                    text:"An exploratory design process encouraging alternative solutions, open experimentation, and reflection. "
                },
                {
                    id:"4",
                    component:"fullparagraph",
                    text:"The initial objective was to examine natural phenomena and systems, exploring both intensive attributes like heat, pressure, density, and speed, as well as extensive attributes like time, distance, scale, and movement. Building upon these studies, the students developed their own rule-based behavioral systems, which were then tested as analog prototypes. Subsequently, the students were introduced to design processes based on algorithms."
                },
                {
                    id:"5",
                    component:"imagecontent",
                    text:base_url + "/images/project-images/systemsthinking/system_16.jpg"
                },
                {
                    id:"6",
                    component:"fullparagraph",
                    text:"The students were introduced to Grasshopper, a parametric tool integrated with the CAD software Rhino. Their objective was to transition from analog prototypes to the domain of digital, parametric design using this tool. Employing a bottom-up approach, the students embarked on an iterative and exploratory process, allowing their solutions to evolve from scratch. Through reflective practice and experimentation, they explored alternative approaches. The outcomes of the workshop were later showcased at AOD."
                },
                {
                    id:"8",
                    component:"imagecontent",
                    text:base_url + "/images/project-images/systemsthinking/system_21.jpg"
                },
                {
                    id:"9",
                    component:"imagecontent",
                    text:base_url + "/images/project-images/systemsthinking/system_22.jpg"
                },
                {
                    id:"10",
                    component:"imagecontent",
                    text:base_url + "/images/project-images/systemsthinking/system_30.jpg"
                },
                {
                    id:"10",
                    component:"imagecontent",
                    text:base_url + "/images/project-images/systemsthinking/system_34.jpg"
                }
            ]
        }
    }]
}

];
