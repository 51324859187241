import React, { useEffect } from 'react';
import './Project.css';
import { useLocation, Link } from "react-router-dom";
import Components from './components';
//import ReactGA from 'react-ga';

const Project = (props) => {
  const location = useLocation();
  const state = location.state;
  //console.log(state);

  useEffect(() => {
    // Track the page view when the Home component mounts
    //ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <>
    
      {state && (
     
        <div>
        
        {state.map((state, index) => {

          
        return (
         
          <div key={index}>

            

            <div className="project-description-container">
              <div className="project-title">{state.projecttitle}</div>
              <div className="project-client">{state.client}</div>
            </div>
             <div className="feature-fill">
                <img src={state.heroimageurl}/>                    
             </div>
             <div className="project-description-container">
                 
                  <div className="project-description">{state.largetag}</div>
                  <div className="project-content">
                    {state.content.body.map(block => Components(block))}
                    <div style={{display:'flex', justifyContent:'center', marginBottom:'6em'}}>
                      <Link to="/work"><div className='projects-button'>BACK TO WORK</div></Link>
                    </div>
                  </div>
             </div>

             
             
          </div>
        );
      })}


        </div>
         
      )}
     
   
    </>
  );
};


/*

const Project = ({ data }) => {
  return (
   
   
   <div></div>
     
  );
};
*/
export default Project;