import React from 'react';
import './HeroProjectText.css';








const HeroProjectText = ({ subject, title, description, backgroundcolor, fontstyle, project }) => {
  
  
  
  return (
  
    
     
     <div>
     
      <div className="heroproject-text-container" style={{ backgroundColor:backgroundcolor}}>
        
        <p className="subject" style={{ color:fontstyle.color, marginTop:fontstyle.marginTop}}>{subject}</p>
        <h2 className="title hover-underline-animation" style={{ color:fontstyle.color, fontSize:fontstyle.fontSize, fontStyle:fontstyle.fontStyle}}>{title}</h2>
        <p className="description" style={{ color:fontstyle.color}}>{description}</p>
      
      </div>
      </div>
  );
};

export default HeroProjectText;


