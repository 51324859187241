import React, { Component } from 'react';
import './NavigationMenu.css';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';

class NavigationMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonAIsHovering: false,
      buttonBIsHovering: false,
      buttonCIsHovering: false,
      buttonDIsHovering: false,
    };
  }

  handleHoverChange(button) {
    return () => {
      this.setState((prevState) => ({
        [button]: !prevState[button],
      }));
    };
  }

  

  render() {
    const { buttonAIsHovering, buttonBIsHovering, buttonCIsHovering, buttonDIsHovering } = this.state;
    const { handleClick } = this.props;

   

    return (
      <div className="navigation-menu">
        <ul>
          <li>
            <Link
              to="/cv"
              className="hover-underline-animation"
              href="http://localhost:3000"
              onMouseEnter={this.handleHoverChange('buttonAIsHovering')}
              onMouseLeave={this.handleHoverChange('buttonAIsHovering')}
              onClick={handleClick}
            >
              {buttonAIsHovering ? 'CURRICULUM VITAE' : 'EXPERTISE'}
            </Link>
          </li>
          <li>
            <Link
              to="/work"
              className="hover-underline-animation"
              href="http://localhost:3000"
              onMouseEnter={this.handleHoverChange('buttonBIsHovering')}
              onMouseLeave={this.handleHoverChange('buttonBIsHovering')}
              onClick={handleClick}
            >
              {buttonBIsHovering ? 'PORTFOLIO' : 'WORK'}
            </Link>
          </li>
          <li>
            <Link
              to="/about"
              className="hover-underline-animation"
              href="http://localhost:3000"
              onMouseEnter={this.handleHoverChange('buttonCIsHovering')}
              onMouseLeave={this.handleHoverChange('buttonCIsHovering')}
              onClick={handleClick}
            >
              {buttonCIsHovering ? 'JEFF' : 'ABOUT'}
            </Link>
          </li>
          <li>
            <Link
              to="/contact"
              className="hover-underline-animation"
              href="http://localhost:3000"
              onMouseEnter={this.handleHoverChange('buttonDIsHovering')}
              onMouseLeave={this.handleHoverChange('buttonDIsHovering')}
              onClick={handleClick}
            >
              {buttonDIsHovering ? "IT" + "'" + "LL BE AWESOME" : 'REACH OUT'}
            </Link>
          </li>
        </ul>
      </div>
    );
  }
}

export default NavigationMenu;




{/*
import { useState } from 'react'
import './NavigationMenu.css';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';

function useHover() {
  const [hovering, setHovering] = useState(false)
  const onHoverProps = {
    onMouseEnter: () => setHovering(true),
    onMouseLeave: () => setHovering(false),
  }
  return [hovering, onHoverProps]
}

function NavigationMenu() {

  const [buttonAIsHovering, buttonAHoverProps] = useHover()
  const [buttonBIsHovering, buttonBHoverProps] = useHover()
  const [buttonCIsHovering, buttonCHoverProps] = useHover()
  const [buttonDIsHovering, buttonDHoverProps] = useHover()

  return (
    <div className="navigation-menu">


      <ul>

        <li >
            <Link to="/cv" className="hover-underline-animation" href="http://localhost:3000" {...buttonAHoverProps}>
                {buttonAIsHovering ? "CURRICULUM VITAE" : "EXPERTISE"}
            </Link>
         
        </li>
        <li >
          <Link to="/work" className="hover-underline-animation" href="http://localhost:3000" {...buttonBHoverProps}>
              {buttonBIsHovering ? "PORTFOLIO" : "WORK"}
          </Link>
        </li>

        <li >
       
            <Link to="/about" className="hover-underline-animation" href="http://localhost:3000" {...buttonCHoverProps}>
                {buttonCIsHovering ? "JEFF" : "ABOUT"}
            </Link>
        </li>
        <li >
          <Link to="/contact" className="hover-underline-animation" href="http://localhost:3000" {...buttonDHoverProps}>
              {buttonDIsHovering ? "REACH OUT" : "CONTACT"} 
          </Link>
        </li>
       
      </ul>
    </div>
  );
}

export default NavigationMenu;
*/}