import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import './Hero.css';
import NavigationMenu from './NavigationMenu';
import { base_url } from './Constants.js';

const MyComponent = () => {

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
   setIsMenuOpen(!isMenuOpen);
  };

  const closeModal = () => {
   
    setIsMenuOpen(false);
  };



  return (
    <div className="my-component-container">
       <div className="profile-container overlay">
          <Link to="/home" className="profile-picture" onClick={closeModal}>
            <img src={base_url + '/images/jeffrey_david_serio_profile.png'} alt="Profile" className="profile-image" />
          </Link>
          <div
          className={`hamburger-menu ${isMenuOpen ? 'open' : 'close'}`}
          onClick={toggleMenu}
          >
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
        </div>
      </div>
      

      <div
       className={`modal ${isMenuOpen ? 'open' : 'close'}`}
        /*onAnimationEnd={toggleMenu}*/
      >
         <div className="text-container">
            <div className="modal-content">
              <NavigationMenu handleClick={closeModal}/>
            </div>
        </div>
      </div>
    </div>
  );
};

export default MyComponent;