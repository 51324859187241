import React, { Component } from 'react';
import './Futures.css';
//import ReactGA from 'react-ga';


class Futures extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSvgLoaded: [false, false, false], // Track if each SVG has been loaded
    };

    this.containerRefs = [
      React.createRef(),
      React.createRef(),
      React.createRef(),
    ];
    this.intersectionObservers = [];
  }

  componentDidMount() {

    //ReactGA.pageview(window.location.pathname + window.location.search);
    // Initialize the Intersection Observer for each phase-background element
    this.intersectionObservers = this.containerRefs.map((ref, index) =>
      new IntersectionObserver(
        (entries) => this.handleIntersection(entries, index),
        {
          root: null, // Use the viewport as the root
          rootMargin: '0px',
          threshold: 0.1, // Trigger when 10% of the element is visible
        }
      )
    );

    this.containerRefs.forEach((ref, index) => {
      if (ref.current) {
        this.intersectionObservers[index].observe(ref.current);
        // Check initial intersection for each element
        const initialIntersection = this.intersectionObservers[index].root
          ? this.intersectionObservers[index].root.contains(ref.current)
          : false;
        if (initialIntersection) {
          this.loadSvg(index);
        }
      }
    });
  }

  componentWillUnmount() {
    // Cleanup the Intersection Observers when the component unmounts
    this.intersectionObservers.forEach((observer, index) => {
      if (observer && this.containerRefs[index].current) {
        observer.unobserve(this.containerRefs[index].current);
      }
    });
  }

  handleIntersection = (entries, index) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting && !this.state.isSvgLoaded[index]) {
        this.loadSvg(index);
      }
    });
  };

  loadSvg = (index) => {
    // Perform the SVG loading logic here
    // For example, set a state flag when the SVG has finished loading
    // to trigger rendering of the SVG element

    // Simulating a delay for demonstration purposes
    setTimeout(() => {
      const updatedSvgLoaded = [...this.state.isSvgLoaded];
      updatedSvgLoaded[index] = true;

      this.setState({
        isSvgLoaded: updatedSvgLoaded,
      });
    }, 2000);
  };

  render() {
    const { isSvgLoaded } = this.state;

    return (
      <div>
        <div className='futures-component'>
          <div className='futures-container'>
            <div className='futures-title'>Futures Design</div>
            <div className='left-paragraph'>
            A unique approach developed over years of practicing design, strategy, and bringing futures to life through the novel use of technology. And a forth coming book offering a step-by-step guide integrating design principles with forwad-thinking approaches. Empowering the responsible creation of innovative products, services, and experiences that bring desired futures to life.
            </div>
          </div>
          <div ref={this.containerRefs[0]} className='phase-background' style={{ backgroundColor: 'rgb(209, 160, 242)'}}>
            <div className='phase-content-container'>
            <div className='phase-text-title' style={{ color: 'rgb(255, 255, 255)'}} >Sense</div>
            <div className='phase-object-container'>
              <div className='object-container'>
                {isSvgLoaded[0] ? (
                  <object
                    className='svg-object'
                    type='image/svg+xml'
                    data='/images/project-futures/arrows/sense_making.svg'
                  />
                ) : (
                  <div className='loading-indicator'></div>
                )}
              </div>
              </div>
              <div className='phase-text-description' style={{ color: 'rgb(255, 255, 255)'}}>Understand the user, challenge assumptions, discover new opportunities, and find patterns in signals of change.</div>
              
             
            </div>
          </div>
          <div ref={this.containerRefs[1]} className='phase-background' style={{ backgroundColor: 'rgb(30, 50, 100)'}}>
            <div className='phase-content-container'>
            <div className='phase-text-title' style={{ color: 'rgb(255, 255, 255)'}} >Map</div>
            <div className='phase-object-container'>
              <div className='object-container'>
                {isSvgLoaded[1] ? (
                  <object
                    className='svg-object'
                    type='image/svg+xml'
                    data='/images/project-futures/arrows/world_building.svg'
                  />
                ) : (
                  <div className='loading-indicator'></div>
                )}
              </div>
              </div>
              <div className='phase-text-description' style={{ color: 'rgb(255, 255, 255)'}}>Extrapolate signals, crystalize trends, reveal shifts, radical alternatives, new products or services, craft narratives, envision future worlds, build prototypes and experiment.</div>
              
            </div>
          </div>
          <div ref={this.containerRefs[2]} className='phase-background' style={{ backgroundColor: 'rgb(241, 119, 137)'}}>
            <div className='phase-content-container'>
            <div className='phase-text-title' style={{ color: 'rgb(255, 255, 255)'}} >Make</div>
            <div className='phase-object-container'>
              <div className='object-container'>
                {isSvgLoaded[2] ? (
                  <object
                    className='svg-object'
                    type='image/svg+xml'
                    data='/images/project-futures/arrows/change_making.svg'
                  />
                ) : (
                  <div className='loading-indicator'></div>
                )}
              </div>
              </div>
              <div className='phase-text-description' style={{ color: 'rgb(255, 255, 255)'}}>Explore relationships and implications, move beyond the known, prioritize futures, roadmap and align around the products and services, build capacities, create the products and services manifesting change.</div>
              
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Futures;
