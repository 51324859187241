import React, { useEffect, useState } from 'react';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import './ProtectedImage.css';

const storage = getStorage();

export default props => {
  const [imageURL, setImageURL] = useState('');

  useEffect(() => {
    const storageRef = ref(storage, props.block.text);

    getDownloadURL(storageRef)
      .then((url) => {
        //console.log("protected content images " + url)
        setImageURL(url);
      })
      .catch((error) => {
        //console.log('Error retrieving image URL: ', error);
      });
  }, [props.block.text]);

  return <img src={imageURL} className="image-content" />;
};
