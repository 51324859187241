import React, { Component } from 'react';
import './CV.css';
import SkillsList from './SkillsList';
//import ReactGA from 'react-ga';


class CV extends Component {
    constructor(props) {
      super(props);
      this.state = {};
    }
   
    componentDidMount() {
        // Track the page view when the Home component mounts
        //ReactGA.pageview(window.location.pathname + window.location.search);
      }
   
    render() {
        
        const skills = ['Co-creation', 'Design Thinking', 'UX Design', 'UX Strategy', 'Agile', 'Scrum', 'User Stories', 'Story Points', 'Ideation', 'Customer Journey', 'Wireframe', 'Insights & Analysis', 'Scenarios', 'Information Architecture', 'Behavioral Design', 'Task Flows', 'User Requirements', 'Workshops', 'Interface Design', 'Design Systems', 'User Research', 'MVPs', 'Prototyping', 'Testing', 'Figma', 'Photoshop', 'Indesign', 'Illustrator', 'Rhinoceros', 'Unity', 'Unreal Engine', 'Javascript', 'React', 'HTML', 'NodeJS', 'C++', 'Java', 'Objective C', 'C#', 'SQL', 'Docker', 'GIT', 'PHP', 'Data Modeling', 'AI', 'ML Models'];

        return ( 
        
            <div>
            <div className='cv-key-container'>
                <div className='key-header-text'>Senior Digital Product Designer, Creative Technologist, Project Manager</div>
            </div>
            <div className='cv-text-container'>
            <div className='text-header-column'>Skills / Competencies</div>
            </div>
            
            <SkillsList skills={skills} />
           
            
            <div className='cv-text-container'>
            <div className='text-header-column'>Award Winning Projects</div>
            <div className="text-row">
                    <div className="cv-text-column">Red Dot Design Award</div>
                    <div className="cv-text-column">Audi Sphere<br></br>Audi Paris</div>
            </div>
            <div className="text-row">
                    <div className="cv-text-column">IF Communication Design Award</div>
                    <div className="cv-text-column">Audi Sphere<br></br>Audi Urban Future</div>
            </div>
            <div className="text-row">
                    <div className="cv-text-column">Adam Award</div>
                    <div className="cv-text-column">Audi Sphere</div>
            </div>
            <div className="text-row">
                    <div className="cv-text-column">ADC Deutschland</div>
                    <div className="cv-text-column">Audi Sphere<br></br>Audi Paris</div>
            </div>
            <div className="text-row">
                    <div className="cv-text-column">Cannes Lion</div>
                    <div className="cv-text-column">Audi Sphere</div>
            </div>
            
            <div className="text-row">
                    <div className="cv-text-column">Shortlisted for Der Deutsche Lichtdesign</div>
                    <div className="cv-text-column">Photophore</div>
            </div>
            <div className="text-row">
                    <div className="cv-text-column">Shortlisted for The Danish Lighting</div>
                    <div className="cv-text-column">Saxo Reactive Ceiling</div>
            </div>
            <div className="text-row">
                    <div className="cv-text-column">Shortlisted Index Award</div>
                    <div className="cv-text-column">AARHUS CO2030</div>
            </div>
            </div>

            <div className='cv-text-container'>
                
                <div className='text-header-column'>Experience</div>

                <div className="text-row">
                    <div className="cv-text-column">02/14 -</div>
                    <div className="cv-text-column"><div className="cv-text-column-bold">Senior Digital Product Designer and Project Manager</div>Independent<br></br>Digital Product & Concept Design / User Research / Technology Specialist<br></br>
Conceptualizing, researching & cocreating, designing, prototyping, developing, and deploying projects within the art and healthcare sector.</div>
                </div>

                <div className="text-row">
                    <div className="cv-text-column">02/14 -</div>
                    <div className="cv-text-column"><div className="cv-text-column-bold">Senior Lecturer & Coordinator</div>Business Academy Aarhus<br></br>Lecturer / Team Leader / International Coordinator / Internship Coordinator<br></br>Organizing and leading projects with industry partners, Teaching User Experience Design, User Research, Digital Design, Design Systems, Digital Technologies (UI, AI, ML, IoT, VR, AR), & Frontend in Digital Concept Development and Multimedia Design.</div>
                </div>

                <div className="text-row">
                    <div className="cv-text-column">01/21 - 8/22</div>
                    <div className="cv-text-column"><div className="cv-text-column-bold">Project Manager - Research & Innovation</div>Business Academy Aarhus<br></br>Hello Future - Developed A Design Process & Framework Combining Strategic Foresight & Design Thinking<br></br>Worked with product design and strategy, defining a process for companies to utilize insights from creative research to conceptualize, design and develop new products. Worked with three local companies in the formation and creation of three new products.</div>
                </div>
            
                <div className="text-row">
                    <div className="cv-text-column">11/08 - 02/14</div>
                    <div className="cv-text-column"><div className="cv-text-column-bold">Digital Designer</div>Kollision <br></br>Digital Design / User Experience / Software Eng. / Creative Technologist<br></br>Conceptual development and design of interactivity and digital products and installations for global brands and public organizations.
Led the research, design, development, and implementation of novel interactive technologies and environments.</div>
                </div>
                <div className="text-row">
                    <div className="cv-text-column">10/07 - 10/08</div>
                    <div className="cv-text-column"><div className="cv-text-column-bold">Research Assistent</div><br></br> Arhus School of Architecture & Katrineberg Interactive Spaces<br></br>Mobile Home Center Project<br></br>Prototyped physical and digital artefacts and interfaces for Bang and Olufsen</div>
                </div>
                <div className="text-row">
                    <div className="cv-text-column">03/08 - 01/09</div>
                    <div className="cv-text-column"><div className="cv-text-column-bold">Teaching Assistent Aalborg University</div><br></br>3rd Semester Industrial Design & Digital Design: Semester Theme: Experience and Product<br></br>Lecture: Design Methodologies, Scripting as a Design Tool<p/>7th Semester Digital Design:
Semester Theme: <br></br>World of Flow and Interaction Lecture: <br></br>Advanced Geometries and Spatial Relations</div>
                </div>
            
                <div className='text-header-column'>Education<div className='text-header-content'>M.Sc. Eng from AALBORG UNIVERSITY, Aalborg DK 2007 <br></br>Architecture & Design with a specialization in Digital Design <br></br>Concentrations: Non-Standard Architecture, Interactive Environments <br></br>Thesis: Extending the Stages, Designed and developed interactive installation leveraging ubiquotous computing for experience design at Nibe Festival</div></div>
                <div className='text-header-content'>B.S. from OHIO UNIVERSITY, Athens, Ohio USA 2004<br></br>Combined Computer Science and International Studies major<br></br>Final Project: Designed, documented, and wrote a Multi-Source File Accessing System program allowing the sharing of files with network bandwidth considerations</div>

                <div className='text-header-column'>Presentations</div>
                <div className='text-header-content'>Serio Jeffrey D. (2017). Creating Digital Experiences. Personal portfolio of digital websites, applications, and installations presented at Humber College’s Web Design and Interactive Media, Toronto.</div>
                <div className='text-header-content'>Serio Jeffrey D., et al (2006). Digital Tools and Techniques. Aalborg’s Marine Museum and NoRA (Nordic Research Application) projects presented at the University of Osaka, School of Design, Japan.</div>
                <div className='text-header-content'>Serio Jeffrey D., et al (2006). Change and Creation. Lighting design project presented at Frederikshavn’s Light Visions/ELDA+ Conference.</div>
           
                <div className='text-header-column'>Books</div>
                <div className='text-header-content'>Serio, Jeffrey D. (2022) Hello Future Forsknings-og innovationspublikation. Aarhus: Business Academy Aarhus (26).</div>
                <div className='text-header-content'>Serio Jeffrey D, et al (2006). NoRA - Nordic Research Application, “Cities: People, Society, Architecture: 10th International Architecture Exhibition - Venice Biennale,” 178- 179.</div>

                <div className='text-header-column'>Papers</div>
                <div className='text-header-content'>Serio Jeffrey D. (2023). Design & Futures - A Process Model Integrating Design Thinking and Strategic Foresight. HCI International Conference Proceedings. Springer: CCIS</div>
                <div className='text-header-content'>Serio Jeffrey D, et al (2015). Experiment in alternative evolutionary design strategy for smart city design. IASS Conference Proceedings. Journal of IASS.</div>

                
                <div className='text-header-column'>Languages</div>
                <div className='text-header-content'>English-Fluent</div>
                <div className='text-header-content'>Danish-Fluent</div>
                <div className='text-header-content'>Spanish-Intermediate</div>

            </div>
            </div>
        );
    }

}

export default CV;
