import React, { Component } from 'react';
import './About.css';
import HeroProjectText from './HeroProjectText'; 
import HeroProjectImage from './HeroProjectImage';
import { base_url } from './Constants.js';
//import ReactGA from 'react-ga';


class About extends Component {
    constructor(props) {
      super(props);
      this.state = {};
    }

   
    componentDidMount() {
      // Track the page view when the Home component mounts
     // ReactGA.pageview(window.location.pathname + window.location.search);
    }
  

    render() {


        const textstyle = {
            color: "black",
            backgroundColor: "white",
            fontSize: "4em",
            fontStyle: "italic",
            marginTop:"0px"
           
          };


        return (
            <div className='about-container'>
           <div className='about-text-container'> 

            <div style={{ textDecoration:'none'}}>
            <HeroProjectText style={{ backgroundColor:'white'}}
              key={'1'}
              description={'I was born in Rochester New York, USA. I graduated from Ohio University studying Computer Science and received a Masters in Civil Engineering in Architecture and Design specializing in Digital Design from Aalborg University, Denmark. Bridging technology and the traditions of Danish Design and Participatory Design has been my professional passion for over 15 years which has lead to new approaches, processes, and projects across the world from Copenhagen to Venice to Miami and clients from global bands, institutions, and companies. Personally as an American living in Denmark I strive to marry my enthusiastic and optimistic American vibe all the while hygging. I enjoy physical activities like skiing and running and am always up to try new things like learning to surf with my three children in the winter.'}
              backgroundcolor={'white'}
              fontstyle = {textstyle}
              
            />
            </div>
            </div>
            <div className='about-image-container'> 
            <HeroProjectImage 
              key={'1'}
              image={base_url + '/images/jds_surfing.png'}
            />
            </div>
        </div>
        );
    }

}

export default About;