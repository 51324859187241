import React from 'react';
import './HeroProjectManager.css';
import HeroProjectImage from './HeroProjectImage';
import HeroProjectText from './HeroProjectText';
import Carousel from './Carousel';
import {  Link } from 'react-router-dom';
import { base_url } from './Constants.js';


const HeroProjectManager = () => {

  const textstyle = {
    color: "white",
    backgroundColor: "black",
   
  };

    const hero_images = [
        {
          id: 1,
          image: base_url + '/images/project-featured/FutureDeliveryRoomFeatured.png',
          projectUrl: 'project',
          project:[{
            heroimageurl:"https://manypossibles.com/wp-content/uploads/2020/09/future-birth-room-esben.png",
            client:"Herning Hospital, w/ Electrotexture",
            projecttitle: "The Future Delivery Room",
            largetag:"A hybrid digital and physical sensual experience for our emotional and physical well-being.",
            partners:"Electrotexture, Esben Bala Skoube",
            shortdescription:"Since January 2015 the maternity ward of Herning Hospital has been helping to evoke peace and security to future parents. And calm and well-being to the newest members of the world within their Future Delivery Room.",
            content:{
                body:[
                    {
                        id:"1",
                        component:"leftparagraph",
                        text:"For some time now the maternity ward of Herning Hospital has been helping to evoke peace and security to future parents. And calm and well-being to the newest members of the world within their Future Delivery Room."
                    },
                    {
                        id:"2",
                        component:"fullparagraph",
                        text:"The Future Delivery Room transforms the typical clinical and sterile hospital into an enveloping atmosphere combining the Danish home and nature to ensure a positive birth experience."
                    },
                    {
                        id:"3",
                        component:"imagecontent",
                        text:"http://futureathand.io/wp-content/uploads/2020/09/Fremtidens-Fødestue-Herning-Winter.png"
                    },
                    {
                        id:"4",
                        component:"rightbold",
                        text:"Personalization, Control, & Transparency Gives Peace of Mind"
                    },
                    {
                        id:"5",
                        component:"rightparagraph",
                        text:"Essential to the deliver room is ability control and design ones own atmosphere making the room unique to families needs and desires while actively involving the non birthing partner in the birth. The Birth GPS within the app makes the stages of the child birth transparent and informs the future parents where they are in the process and what to expect."
                    },
                    {
                        id:"6",
                        component:"imagecontent",
                        text:"http://futureathand.io/wp-content/uploads/2020/09/future-birth-room-app.png"
                    }
                ]
            }
        }]
        },
        {
            id: 2,
            image: base_url + '/images/project-featured/BirthGPSFeatured.png',
            projectUrl: 'project',
            project:[{
                heroimageurl: base_url + "/images/project-images/birth-gps/birth_gps_05.png",
                client:"Herning Hospital",
                projecttitle: "Birth GPS",
                largetag:"Navigating the Journey of Birth, Calm, Confident and with Clarity",
                partners:"Electrotexture, Esben Bala Skoube",
                shortdescription:"Birth GPS is an extraordinary app designed to empower expectant mothers and fathers on their transformative journey through childbirth. With its precise tracking, intuitive features, and the ability to choose from a range of atmospheres and moods, Birth GPS ensures a personalized and empowering birthing experience in The Future Delivery Room.",
                content:{
                    body:[
                        {
                            id:"1",
                            component:"leftparagraph",
                            text:"The Birth GPS empowers expectant mothers and father to exert complete control over the ambiance of their birthing environment and an indispensable tool that utilizes advanced algorithms to provide a visualized estimation of the birth time, allowing parents to better prepare for each stage of labor."
                        },
                        {
                            id:"2",
                            component:"fullparagraph",
                            text:"Mothers can immerse themselves in serene and breathtaking landscapes projected across every corner of the room, transforming the sterile hospital setting into a tranquil haven. The app also features the ability to select different atmospheres, moods, sounds, and soothing colored lights that help create a calming and relaxing atmosphere, promoting a sense of peace and serenity. "
                        },
                        {
                            id:"3",
                            component:"fullparagraph",
                            text:"Recognizing the importance of the expecting father's role, the app also allows fathers to actively participate and contribute to the birth experience. By providing them with a feeling of control and helpfulness, Birth GPS ensures that both parents can share in the joy, support, and emotional connection during this monumental event, fostering a deep bond and a positive birthing experience for the entire family."
                        },
                        {
                            id:"4",
                            component:"imagecontent",
                            text: base_url +"/images/project-images/birth-gps/birth_gps_02.png"
                        },
                        {
                            id:"5",
                            component:"rightparagraph",
                            text:"The Birth GPS equips parents with knowledge about the progression of labor, providing detailed descriptions and insights, allowing them to navigate each phase with confidence and support. Ultimately, this invaluable functionality helps expectant parents stay informed, mentally and emotionally engaged, and actively involved in the birth process, fostering a deeper sense of partnership and mutual understanding between the mother and father during this transformative journey."
                        },
                        {
                            id:"6",
                            component:"imagecontent",
                            text: base_url + "/images/project-images/birth-gps/birth_gps_03.png"
                        },
                        {
                            id:"7",
                            component:"fullparagraph",
                            text:"Immersive imagery carefully curated depicting the serene and familiar Danish landscapes that evoke feelings of protection and calm. These captivating visuals, captured across different seasons and times of day, transport you to meaningful locations, fostering a sense of connection within the hospital setting. Harmonious and soothing colors, carefully selected in tone-based variations, create an enveloping and calming ambiance, embracing the range of emotions—anticipation, excitement, and nervousness—associated with childbirth. The intuitive icons and symbols guide you effortlessly through the Birth GPS, ensuring clear communication and minimizing cognitive load. With their light and airy design, inspired by nature, these icons maintain legibility and accessibility, allowing you to navigate the app with ease. "
                        },
                        {
                            id:"8",
                            component:"imagecontent",
                            text: base_url + "/images/project-images/birth-gps/birth_gps_07.png"
                        },
                        {
                            id:"9",
                            component:"fullparagraph",
                            text:"The components and design system serves as a central repository of design guidelines, patterns, and best practices, ensuring a unified and harmonious user experience across all screens and functionalities. This systematic approach not only saves time and effort but also guarantees a high level of usability, accessibility, and visual coherence. Ultimately, the design components and system create a polished and refined application that seamlessly integrates all the desired features and provides an exceptional user experience for expectant mothers and fathers using Birth GPS."
                        },
                        {
                            id:"10",
                            component:"imagecontent",
                            text: base_url + "/images/project-images/birth-gps/birth_gps_06.png"
                        }
                    ]
                }
            }]
        },
        {
          id: 3,
          image: base_url + '/images/project-featured/VirtualMachineHouseFeatured.png',
          projectUrl: '/project',
          project:[{
            heroimageurl: base_url + '/images/project-images/driesel/prototypes_3.png',
            client:"Westerberg & Driesel",
            projecttitle:"The Virtual Equipment House",
            largetag:"Revolutionizing Smart Construction Equipment Management to Unlock Sustainability, Efficiency, and Profitability",
            partners:"",
            shortdescription:"The Virtual Equipment House by Westerberg and Driesel is a groundbreaking digital platform that transforms how smart construction equipment is managed. With a strong emphasis on user experience, this platform drives sustainability, efficiency, and profitability in the construction industry.",
            content:{
                body:[
                    {
                        id:"1",
                        component:"fullparagraph",
                        text:"Combining Westerberg's expertise in durable lithium batteries and IoT Smart Metering with Driesel's expansion into sustainable and smart construction equipment, The Virtual Equipment House is a platform of digital twins that opens horizons on managing electrified construction equipment. Making the intangible qualities of electricity usage and sustainability tangible."
                    },
                    {
                        id:"2",
                        component:"imagecontent",
                        text: base_url + '/images/project-images/driesel/low-fidelity.png'
                    },
                    {
                        id:"3",
                        component:"leftparagraph",
                        text:"With a vision for a whole new class of electrified equipment in the construction industry, this project places a significant emphasis on data utilization, user needs, and automation to achieve intelligent maintenance systems, real-time monitoring, diagnostics, and predictive analytics, while optimizing performance and utilization through data-driven insights."
                    }, 
                    {
                        id:"4",
                        component:"imagecontent",
                        text:base_url + '/images/project-images/driesel/3dprint.png'
                    },
                    {
                        id:"5",
                        component:"fullparagraph",
                        text:"The platform's elements are meticulously designed. Employing a user experience design approach and methodologies the project outlines functional requirements to prioritize construction professionals to effortlessly manage and monitor equipment. The information experience was architected, wireframed, and tested."
                    },
                    {
                        id:"6",
                        component:"imagecontent",
                        text:base_url + '/images/project-images/driesel/ia.png'
                    },
                    {
                        id:"6",
                        component:"rightparagraph",
                        text:"By translating Westerberg's battery expertise into the digital realm, this project carefully considers the technical skills and resources required for platform actualization, backcasting was used to define the roadmap and necessary capacities to go from vision and concept to implementation."
                    },
                    {
                        id:"6",
                        component:"imagecontent",
                        text:base_url + '/images/project-images/driesel/stakeholder.png'
                    }
                ]
            }
        }]
        },
        // Add more banner objects as needed
      ];

      const hero_text = [
        {
          id: 1,
          subject: 'Project',
          title: 'The Future Delivery Room',
          description: 'A hybrid digital and physical sensual experience for our emotional and physical well-being. The Future Delivery Room digitally transforms the typical clinical and sterile hospital into an enveloping atmosphere combining the Danish home and nature to ensure a positive birth experience.',
          backgroundcolor:'black',
          textcolor:'white',
          project:[{
            heroimageurl: "https://manypossibles.com/wp-content/uploads/2020/09/future-birth-room-esben.png",
            client:"Herning Hospital, w/ Electrotexture",
            projecttitle: "The Future Delivery Room",
            largetag:"A hybrid digital and physical sensual experience for our emotional and physical well-being.",
            partners:"Electrotexture, Esben Bala Skoube",
            shortdescription:"Since January 2015 the maternity ward of Herning Hospital has been helping to evoke peace and security to future parents. And calm and well-being to the newest members of the world within their Future Delivery Room.",
            content:{
                body:[
                    {
                        id:"1",
                        component:"leftparagraph",
                        text:"For some time now the maternity ward of Herning Hospital has been helping to evoke peace and security to future parents. And calm and well-being to the newest members of the world within their Future Delivery Room."
                    },
                    {
                        id:"2",
                        component:"fullparagraph",
                        text:"The Future Delivery Room transforms the typical clinical and sterile hospital into an enveloping atmosphere combining the Danish home and nature to ensure a positive birth experience."
                    },
                    {
                        id:"3",
                        component:"imagecontent",
                        text:"http://futureathand.io/wp-content/uploads/2020/09/Fremtidens-Fødestue-Herning-Winter.png"
                    },
                    {
                        id:"4",
                        component:"rightbold",
                        text:"Personalization, Control, & Transparency Gives Peace of Mind"
                    },
                    {
                        id:"5",
                        component:"rightparagraph",
                        text:"Essential to the deliver room is ability control and design ones own atmosphere making the room unique to families needs and desires while actively involving the non birthing partner in the birth. The Birth GPS within the app makes the stages of the child birth transparent and informs the future parents where they are in the process and what to expect."
                    },
                    {
                        id:"6",
                        component:"imagecontent",
                        text:"http://futureathand.io/wp-content/uploads/2020/09/future-birth-room-app.png"
                    }
                ]
            }
        }]

        },
        {
            id:"2",
            subject: 'Project',
            title: "Birth GPS",
            backgroundcolor:'black',
            textcolor:'white',
            description: "Navigating the journey of birth with confidence and clarity. The Birth GPS enables expectant parents to chart their course, choose their ambiance, and embrace the transformative journey of childbirth.",
            project:[{
                heroimageurl: base_url + "/images/project-images/birth-gps/birth_gps_05.png",
                client:"Herning Hospital",
                projecttitle: "Birth GPS",
                largetag:"Navigating the Journey of Birth, Calm, Confident and with Clarity",
                partners:"Electrotexture, Esben Bala Skoube",
                shortdescription:"Birth GPS is an extraordinary app designed to empower expectant mothers and fathers on their transformative journey through childbirth. With its precise tracking, intuitive features, and the ability to choose from a range of atmospheres and moods, Birth GPS ensures a personalized and empowering birthing experience in The Future Delivery Room.",
                content:{
                    body:[
                        {
                            id:"1",
                            component:"leftparagraph",
                            text:"The Birth GPS empowers expectant mothers and father to exert complete control over the ambiance of their birthing environment and an indispensable tool that utilizes advanced algorithms to provide a visualized estimation of the birth time, allowing parents to better prepare for each stage of labor."
                        },
                        {
                            id:"2",
                            component:"fullparagraph",
                            text:"Mothers can immerse themselves in serene and breathtaking landscapes projected across every corner of the room, transforming the sterile hospital setting into a tranquil haven. The app also features the ability to select different atmospheres, moods, sounds, and soothing colored lights that help create a calming and relaxing atmosphere, promoting a sense of peace and serenity. "
                        },
                        {
                            id:"3",
                            component:"fullparagraph",
                            text:"Recognizing the importance of the expecting father's role, the app also allows fathers to actively participate and contribute to the birth experience. By providing them with a feeling of control and helpfulness, Birth GPS ensures that both parents can share in the joy, support, and emotional connection during this monumental event, fostering a deep bond and a positive birthing experience for the entire family."
                        },
                        {
                            id:"4",
                            component:"imagecontent",
                            text: base_url + "/images/project-images/birth-gps/birth_gps_02.png"
                        },
                        {
                            id:"5",
                            component:"rightparagraph",
                            text:"The Birth GPS equips parents with knowledge about the progression of labor, providing detailed descriptions and insights, allowing them to navigate each phase with confidence and support. Ultimately, this invaluable functionality helps expectant parents stay informed, mentally and emotionally engaged, and actively involved in the birth process, fostering a deeper sense of partnership and mutual understanding between the mother and father during this transformative journey."
                        },
                        {
                            id:"6",
                            component:"imagecontent",
                            text:base_url + "/images/project-images/birth-gps/birth_gps_03.png"
                        },
                        {
                            id:"7",
                            component:"fullparagraph",
                            text:"Immersive imagery carefully curated depicting the serene and familiar Danish landscapes that evoke feelings of protection and calm. These captivating visuals, captured across different seasons and times of day, transport you to meaningful locations, fostering a sense of connection within the hospital setting. Harmonious and soothing colors, carefully selected in tone-based variations, create an enveloping and calming ambiance, embracing the range of emotions—anticipation, excitement, and nervousness—associated with childbirth. The intuitive icons and symbols guide you effortlessly through the Birth GPS, ensuring clear communication and minimizing cognitive load. With their light and airy design, inspired by nature, these icons maintain legibility and accessibility, allowing you to navigate the app with ease. "
                        },
                        {
                            id:"8",
                            component:"imagecontent",
                            text: base_url + "/images/project-images/birth-gps/birth_gps_07.png"
                        },
                        {
                            id:"9",
                            component:"fullparagraph",
                            text: "The components and design system serves as a central repository of design guidelines, patterns, and best practices, ensuring a unified and harmonious user experience across all screens and functionalities. This systematic approach not only saves time and effort but also guarantees a high level of usability, accessibility, and visual coherence. Ultimately, the design components and system create a polished and refined application that seamlessly integrates all the desired features and provides an exceptional user experience for expectant mothers and fathers using Birth GPS."
                        },
                        {
                            id:"10",
                            component:"imagecontent",
                            text: base_url + "/images/project-images/birth-gps/birth_gps_06.png"
                        }
                    ]
                }
            }]
        },
        {
          id: 3,
          subject: 'Project',
          title: 'The Virtual Equipment House',
          description: 'The Virtual Equipment House proposes a management platform for the revolutionizing smart construction equipment to unlock sustainability, efficiency, and profitability.',
          backgroundcolor:'black',
          textcolor:'white',
          project:[{
            heroimageurl: base_url + '/images/project-images/driesel/prototypes_3.png',
            client:"Westerberg & Driesel",
            projecttitle:"The Virtual Equipment House",
            largetag:"Revolutionizing Smart Construction Equipment Management to Unlock Sustainability, Efficiency, and Profitability",
            partners:"",
            shortdescription:"The Virtual Equipment House by Westerberg and Driesel is a groundbreaking digital platform that transforms how smart construction equipment is managed. With a strong emphasis on user experience, this platform drives sustainability, efficiency, and profitability in the construction industry.",
            content:{
                body:[
                    {
                        id:"1",
                        component:"fullparagraph",
                        text:"Combining Westerberg's expertise in durable lithium batteries and IoT Smart Metering with Driesel's expansion into sustainable and smart construction equipment, The Virtual Equipment House is a platform of digital twins that opens horizons on managing electrified construction equipment. Making the intangible qualities of electricity usage and sustainability tangible."
                    },
                    {
                        id:"2",
                        component:"imagecontent",
                        text: base_url + '/images/project-images/driesel/low-fidelity.png'
                    },
                    {
                        id:"3",
                        component:"leftparagraph",
                        text:"With a vision for a whole new class of electrified equipment in the construction industry, this project places a significant emphasis on data utilization, user needs, and automation to achieve intelligent maintenance systems, real-time monitoring, diagnostics, and predictive analytics, while optimizing performance and utilization through data-driven insights."
                    }, 
                    {
                        id:"4",
                        component:"imagecontent",
                        text:base_url + '/images/project-images/driesel/3dprint.png'
                    },
                    {
                        id:"5",
                        component:"fullparagraph",
                        text:"The platform's elements are meticulously designed. Employing a user experience design approach and methodologies the project outlines functional requirements to prioritize construction professionals to effortlessly manage and monitor equipment. The information experience was architected, wireframed, and tested."
                    },
                    {
                        id:"6",
                        component:"imagecontent",
                        text:base_url + '/images/project-images/driesel/ia.png'
                    },
                    {
                        id:"6",
                        component:"rightparagraph",
                        text:"By translating Westerberg's battery expertise into the digital realm, this project carefully considers the technical skills and resources required for platform actualization, backcasting was used to define the roadmap and necessary capacities to go from vision and concept to implementation."
                    },
                    {
                        id:"6",
                        component:"imagecontent",
                        text:base_url + '/images/project-images/driesel/stakeholder.png'
                    }
                ]
            }
        }]
         
        },
        // Add more banner objects as needed
      ];


  return (
    <div className="project-link">
      
    <div className="project-container">
    <Carousel>
        
        {hero_images.map((hero_images, index) => (
         <Link to="/project" state={hero_images.project}>
          <HeroProjectImage  
          key={hero_images.id}
          image={hero_images.image}
          project={hero_images.project}
        />
        </Link>
         ))}
    </Carousel>

    <Carousel>
        
        {hero_text.map((hero_text, index) => (
         <Link to="/project" state={hero_text.project} style={{textDecoration:'none'}}>
          <HeroProjectText
          key={hero_text.id}
          subject={hero_text.subject}
          title={hero_text.title}
          description={hero_text.description}
          backgroundcolor={hero_text.backgroundcolor}
          fontstyle={textstyle}
          project={hero_text.project}
        />
        </Link>
         ))}
    </Carousel>        

    </div>
    </div>
  );
};

export default HeroProjectManager;