import React from 'react';

import './HeroProjectImage.css';

const HeroProjectImage = ({ image, project}) => {
  return (
   
      <div className="image-container">
       
        <img src={image} alt="Banner" />
        
      </div>
    
  );
};

export default HeroProjectImage;